<template>
  <v-row>
     <!-- header -->
    <mini-header :breadcrumbs="breadcrumbs" :hiddenTab="true" :dense="true" :scroll="scroll">
      <template v-slot:main v-if="($helpers.hasPermission(['view_suppliergroup']) && $store?.state?.auth?.account?.suppliergroup_feature)">
        <v-query-builder class="mx-2" :query-map="filters" model="pos" :disabled="groups?.length === 0" />
        <v-btn color="blue-500" v-if="$helpers.hasPermission(['add_suppliergroup'])" @click="handleCreateSegment"><v-icon left>mdi-folder</v-icon>Crear grupo</v-btn>
      </template>
    </mini-header>
   <!-- end header -->
    <PermissionDenied v-if="!($helpers.hasPermission(['view_suppliergroup']) && $store?.state?.auth?.account?.suppliergroup_feature)" />
    <v-col class="px-0 mt-n6 mb-10" v-else>
      <VSkeleton class="mt-6" v-if="loading" />
      <template v-else>
        <v-col cols="12" class="px-0 mt-2">
          <template v-if="groupsList.length">
            <v-row>
              <v-col class="pa-2" v-for="(d, index) in groupsList" :key="`${d.id}-${index}`" cols="6" :md="$vuetify.breakpoint.width < 1200 ? 4 : 3">
                <v-hover v-slot="{ hover }">
                  <v-card
                    class="pa-5 rounded-md"
                    @click.stop="$router.push({ name: 'SuppliersGroupsRetrieveUpdate', params: { id: d?.id ?? 'null' } })"
                    :elevation="hover ? 5 : 0"
                    flat
                  >
                    <v-row no-gutters>
                      <v-avatar :color="d.name !== 'Bloqueados' ? 'blue-100' : 'grey-200'" size="32">
                        <v-icon :color="d.name !== 'Bloqueados' ? 'blue-500' : 'grey-300'" small>mdi-{{ d.name !== 'Bloqueados' ? 'folder' : 'lock' }}</v-icon>
                      </v-avatar>
                      <v-spacer />
                      <v-menu v-model="menu[index]" close-on-click offset-y left :nudge-bottom="0" :nudge-left="10" transition="slide-y-transition" :min-width="182">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mt-n1 mr-n2" text v-bind="attrs" v-on="on" :ripple="false">
                            <v-icon color="grey-700" size="24">mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list class="pa-0 pb-2">
                          <v-list-item class="px-3" v-if="$helpers.hasPermission(['view_supplier'])" :to="{name: 'SupplierListCreate', query: { group: d?.id ?? 'null', ...(d.name && { key: d.name })} }" active-class="transparent" :ripple="false">
                            <v-list-item-title class="body-2">Ir a los proveedores</v-list-item-title>
                          </v-list-item>
                          <v-list-item class="px-3" v-if="$helpers.hasPermission(['view_purchase'])" :to="{name: 'PurchaseList', query: { supplier__group: d?.id ?? 'null', ...(d.name && { name: d.name })} }" active-class="transparent" :ripple="false">
                            <v-list-item-title class="body-2">Ir a los documentos recibidos</v-list-item-title>
                          </v-list-item>
                          <v-list-item class="px-3" v-if="$helpers.hasPermission(['view_purchase']) && $store?.state?.auth?.account?.auto_purchase_sii" :to="{name: 'PendingReceiveList', query: { supplier__group: d?.id ?? 'null', ...(d.name && { name: d.name })} }" active-class="transparent" :ripple="false">
                            <v-list-item-title class="body-2">Ir a los documentos por recibir</v-list-item-title>
                          </v-list-item>
                          <template v-if="!['Bloqueados', 'Sin grupo asociado'].includes(d.name) && ($helpers.hasPermission(['change_suppliergroup'])) || $helpers.hasPermission(['delete_suppliergroup'])">
                            <v-divider class="mx-3 mt-1" />
                            <v-list-item class="px-3 mt-1" @click="handleGroup(d)" v-if="$helpers.hasPermission(['change_suppliergroup'])" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Editar grupo</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" @click="handleDestroySegment(d.id)" v-if="$helpers.hasPermission(['delete_suppliergroup'])" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Eliminar grupo</v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    </v-row>
                    <span class="d-block pt-3">
                      <span :class="`grey-${d.name !== 'Sin grupo asociado' ? '700' : '200'}--text font-weight-semibold body-1 d-inline-block text-truncate`" style="max-width: 90%">{{d.name}}</span>
                    </span>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
            <VMainPagination :count="groupsList.length" />
          </template>
          <v-empty-state v-else title="No existen grupos para mostrar" :isFree="true" type="suppliers" :height="200" customClass="mt-5" />
        </v-col>
      </template>
    </v-col>

    <!-- dialog edit group -->
    <v-dialog v-model="dialogSegment" width="650" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Editar grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogSegment=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-4 pt-6 px-5" style="max-height: 600px !important;">
          <v-list class="pa-0 transparent v-list-form-status">
            <v-list-item class="py-1">
              <v-list-item-title>Nombre del grupo</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.editable.name.$model" :error="$v.editable.name.$error" outlined required single-line dense maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Descripción <v-chip class="ml-2 px-1" x-small color="off">Opcional</v-chip></v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.editable.description.$model" :error="$v.editable.description.$error" outlined required single-line dense maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogSegment=false">Cancelar</v-btn>
          <v-btn color="blue-500" @click="editSegment" :loading="editSegmentLoading">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog edit group -->

     <!-- dialog confirm delete -->
     <v-dialog v-model="dialogDelete" width="759" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Eliminar grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false" icon small retain-focus-on-click><v-icon color="grey-700" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-700--text">
          <v-row class="pl-5" align="center" no-gutters>
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-el-lock.svg`)" :width="168" />
            </v-col>
            <v-col class="pl-6 pr-0">
              <span class="d-block">Al eliminar el grupo, los proveedores no tendrá grupos asociados y estarán sujetos a las reglas de automatización para proveedores sin grupos.</span>
              <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false" outlined>Cancelar</v-btn>
          <v-btn color="blue-500" @click="destroySegment" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->

    <!-- dialog new group -->
    <v-dialog v-model="dialogCreateGroup" width="650" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Crear grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogCreateGroup=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-4 pt-6 px-5" style="max-height: 600px !important;">
          <v-list class="pa-0 transparent v-list-form-status">
            <v-list-item class="py-1">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.newGroup.name.$model" :error="$v.newGroup.name.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pb-2 pt-6">
              <v-list-item-title>Descripción <v-chip class="ml-2 px-1" x-small color="off">Opcional</v-chip></v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="newGroup.description" outlined required single-line dense maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <template>
            <v-list class="pa-0 mt-5 transparent v-list-form-status">
              <v-list-item class="pb-1">
                <v-list-item-title>Proveedores <v-chip class="ml-2 px-1" x-small color="off">Opcional</v-chip></v-list-item-title>
                <v-list-item-subtitle>
                  <v-col class="pa-0">
                    <v-autocomplete
                      v-model="newGroup.suppliers"
                      hide-details
                      single-line
                      color="blue-500"
                      dense
                      outlined
                      :items="suppliersList.filter(({group_details}) => !group_details)"
                      multiple
                      :chips="false"
                      item-text="name"
                      item-value="id"
                      return-object
                      :menu-props="{ overflowY: true, transition: 'slide-y-transition', 'max-width': 450}"
                      :prefix="newGroup.suppliers.length >= 1 ? `${newGroup.suppliers.length === 1 ? `${newGroup.suppliers.length} seleccionado` : `${newGroup.suppliers.length} seleccionados`}` : ''"
                      no-data-text="No existen miembros para mostrar"
                    >
                      <template v-slot:selection="{ props }">
                        <span v-if="props" style=""></span>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-title class="font-weight-regular">
                          <span class="d-inline-block text-truncate" style="max-width: 80%;">{{data.item.name}}</span>
                        </v-list-item-title>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <v-row class="ml-16" no-gutters>
              <v-col cols="2"></v-col>
              <v-col class="pa-0 mt-2 pl-6">
                <v-chip class="mr-2 mb-2 font-weight-light overline chip-users" v-for="(supplier, i) in newGroup.suppliers" :key="`${supplier}${i}`" close @click:close="supplierRemove(supplier, i)" color="blue-100" small label close-icon="mdi-close">
                  {{supplier.name }}
                </v-chip>
                <template v-if="newGroup.suppliers.length > 2">
                  <v-chip class="mr-2 mb-2 font-weight-light overline chip-users" color="blue-100" small label close-icon="mdi-close">
                  {{newGroup.suppliers.length - 2}} más
                </v-chip>
                </template>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogCreateGroup=false">Cancelar</v-btn>
          <v-btn color="blue-500" @click="createGroup" :loading="createGroupLoading">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog new group -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import VMainPagination from '@/components/commons/VMainPagination'
import PermissionDenied from '@/modules/http/views/PermissionDenied'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength } from 'vuelidate/lib/validators'
import VEmptyState from '@/components/commons/VEmptyState'
import VCardList from '@/modules/pos/pos/components/complements/VCardList'
import VHeader from '@/modules/pos/pos/components/complements/VHeader'
import VSkeleton from '@/modules/pos/pos/components/complements/VSkeletonPos'
import ListViewMixin from '@/mixins/ListViewMixin'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'

export default {
  components: {
    VTableHeaders,
    SkeletonList,
    VEmptyState,
    VCardList,
    VHeader,
    VSkeleton,
    VQueryBuilder,
    MiniHeader,
    PermissionDenied,
    VMainPagination
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    dialogCreateGroup: false,
    dialogGroup: false,
    createGroupLoading: false,
    // shortcutsSegment: [],
    confirm: '',
    loadingSuppliers: false,
    dialogDelete: false,
    editSegmentLoading: false,
    dialogSegment: false,
    menu: [false],
    menu1: [false],
    scroll: 0,
    loading: false,
    filters: [
      {
        type: 'text',
        id: 'name',
        label: 'Nombre',
        value: null,
        category: 'a',
        operators: [''],
        countries: ['PE', 'CL']
      }
    ],
    editable: {
      name: '',
      visibility: 1,
      description: '',
      pos: [],
      members: []
    },
    currentDelete: '',
    breadcrumbs: {
      main: 'Recepción',
      children: [
        {
          text: 'Grupos'
        }
      ]
    },
    newGroup: {
      name: '',
      visibility: 1,
      description: '',
      suppliers: []
    }
  }),
  /* eslint-disable */
  computed: {
    ...mapState({
      groupsList: state => state.suppliers.groupsList,
      count: state => state.suppliers.groupsCount,
      suppliersList: state => state.suppliers.suppliersList
    })
  },
  created () {
    if (this.$helpers.hasPermission(['view_suppliergroup']) && this.$store?.state?.auth?.account?.suppliergroup_feature) this.getList()
  },
  methods: {
    handleCreateSegment () {
      this.dialogCreateGroup = true
    },
    createGroup () {
      this.$v.newGroup.$touch()
      if (this.$v.newGroup.$invalid) {
        return false
      }

      this.createGroupLoading = true
      let payload = this.newGroup
      payload.suppliers = payload.suppliers.map(({id}) => id)

      this.$store.dispatch('suppliers/CREATE', {
        resource: 'suppliers/groups',
        query: this.$route.query,
        payload: payload
      })
     .then(() => {
       this.$dialog.message.info('El grupo ha sido creado con éxito.')
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.$v.newGroup.$reset()
        this.dialogCreateGroup = false
        this.createGroupLoading = false
        this.newGroup = {
          name: '',
          visibility: 1,
          description: '',
          suppliers: [],
          members: []
        }
      })
    },
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    getList() {
      this.loading = true
      this.$store.dispatch('suppliers/LIST', {
        resource: 'suppliers/groups',
        query: this.$route.query
      })
      // .then(() => {
      //   if (this.$helpers.hasPermission(['view_supplier'])) {
      //   this.$store.dispatch('suppliers/LIST', {
      //     resource: 'suppliers',
      //     query: {
      //       page_size: 9999,
      //     }
      //   })
      //   .then((resp) => {
      //     if (resp.data.results?.length) {
      //       const suppliersWithoutGroup = [{
      //         description: '',
      //         suppliers: resp.data.results.filter(({ groups }) => !groups?.length),
      //         name: 'Sin grupo asociado',
      //         visibility: 1
      //       }]
      //       this.groups = [...suppliersWithoutGroup, ...this.groupsList.filter(({ name }) => name !== 'Bloqueados'), ...this.groupsList.filter(({ name }) => name === 'Bloqueados')]
      //     }
      //   })
      // }
      // })
      .finally(() => {
        this.loading = false
      })
    },
    handleGroup (item = {}) {
      this.dialogSegment = true
      // this.$store.dispatch('accounts/LIST', {
      //   resource: 'members',
      //   query: {
      //     page_size: 9999,
      //     is_active: true
      //   }
      // })
      // .then((resp) => {
        this.editable = {
          id: item.id,
          name: item.name,
          visibility: item.visibility,
          description: item.description,
          // pos: item.pos
          // members: resp.data.results.filter(({id}) => item.members.includes(id))
        }
      // })
    },
    supplierRemove (item) {
      const index = this.editable.suppliers.indexOf(item)
      if (index >= 0) this.editable.suppliers.splice(index, 1)
    },
    handleDestroySegment (segment = '') {
      this.currentDelete = segment
      this.dialogDelete = true
    },
    editSegment () {
      this.$v.editable.$touch()
      if (this.$v.editable.$invalid) {
        return false
      }

      this.editSegmentLoading = true

      let payload = {...this.editable}
      if ([1, 3].includes(this.editable.visibility)) delete payload.suppliers
      else payload.suppliers = payload.suppliers.map(({id}) => id)

      delete payload.id

      this.$store.dispatch('suppliers/UPDATE', {
        resource: 'suppliers/groups',
        id: this.editable.id,
        query: this.$route.query,
        payload: payload
      })
      .then(() => {
        this.getList()
        this.$dialog.message.info('El grupo se ha actualizado con éxito.')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.$v.editable.$reset()
        this.editable = {
          name: '',
          visibility: 1,
          description: '',
          pos: [],
          members: []
        }
        this.dialogSegment = false
        this.editSegmentLoading = false
      })
    },
    destroySegment () {
      this.$store.dispatch('suppliers/DESTROY', {
        resource: 'suppliers/groups',
        id: this.currentDelete
      })
      .then(() => {
        this.$dialog.message.info('El grupo se ha sido eliminado con éxito')
        this.getList()
        this.addOrRemoveMenu(this.currentDelete)
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.currentDelete = ''
        this.confirm = ''
        this.dialogDelete = false
      })
    },
    remove (item) {
      const index = this.editable.members.indexOf(item)
      if (index >= 0) this.editable.members.splice(index, 1)
    }
  },
  validations: {
    newGroup: {
      name: { required, maxLength: maxLength(64) },
      description: { maxLength: maxLength(64) }
    },
    editable: {
      name: { required, maxLength: maxLength(100) },
      description: { maxLength: maxLength(100) }
    }
  }
}
</script>